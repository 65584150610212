export const settings = {
     "site_name"    : "JBM Healthcare",
     "site_nav"     : [
          {
               "title"   : ["Home","首頁","首页"],
               "url"     : "index",
               "location" : "header,footer"
          },
          {
               "title"   : ["Our Company","我們的簡介","我们的简介"],
               "url"     : "our-company",
               "location" : "header,footer"
          },
          {
               "title"   :  ["Our Business","我們的業務","我们的业务"],
               "url"     : "business",
               "location" : "header,footer"
          },
          {
               "title"   : ["Our Products","我們的產品","我们的产品"],
               "url"     : "products",
               "location" : "header,footer"
          },
          {
               "title"   : ["Investor Relations","投資者關係","投资者关系"],
               "url"     : "investor-relations",
               "location" : "header,footer"
          },
          {
               "title"   : ["Careers","職業發展","职业发展"],
               "url"     : "careers",
               "location" : "header,footer"
          },
          {
               "title"   : ["CSR","企業社會責任","企业社会责任"],
               "url"     : "csr",
               "location" : "header,footer"
          },
          {
               "title"   : ["Contact us","聯繫資料","联系资料"],
               "url"     : "contact",
               "location" : "header,footer"
          },
          {
               "title"   : ["Career Opportunities","業位空缺","业位空缺"],
               "url"     : "vacancies",
               "location" : ""
          },
          {
               "title"   : ["site map",""],
               "url"     : "index",
               "location" : ""
          }
     ],
     "footer" : {
          "disclaimer" : "&copy; JBM (Healthcare) Limited"
     }
}
