
var jquery = require("jquery");
window.$ = window.jQuery = jquery; // notice the definition of global variables here

// var WOW = require("wow.js");

import Swiper from 'swiper';

import * as pageLayout from "./page_layout.js";


$(function() {

      pageLayout.init();

      var mySwiper = new Swiper('.swiper-container', {
           // Optional parameters

              loop: true,
              autoplay: {
                    delay: 2000,
                    disableOnInteraction: false,
                 },
              // If we need pagination
              pagination: {
                el: '.swiper-pagination',
              },

              // Navigation arrows
              navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
              },
              breakpoints: {

                      640: {
                        slidesPerView: 2,
                        spaceBetween: 10,
                      },
                      768: {
                        slidesPerView: 3,
                        spaceBetween: 20,
                      },
                      1024: {
                        slidesPerView: 4,
                        spaceBetween: 20,
                      }
                 }
         }
     );
});
