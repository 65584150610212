

import * as siteConfig from "./site_settings.js";

let settings = siteConfig.settings;
let currLangID = window.localStorage.getItem("langID") || "en";

let langIDMaper = {
     "en" : 0,
     "zh" : 1,
     "zh-Hans" : 2
}

export function init() {

     routePage(function(){

          renderMenus();
          renderEvents();
          renderPageLayout();

          pageElementControl();
     });

     //console.log(currLangID);
}


// private functions


function pageElementControl(){

     var curr_filename = location.href.split("/").slice(-1)[0].split(".");

     switch (curr_filename[0]) {
          case "investor-relations":
          case "investor-relations_zh":
          case "investor-relations_zh-Hans":
               showAnnouncement();
               break;
          default:
     }
}


function showAnnouncement(){

     var cutoff_date;

     var pool =  $(".element-scheduler")
     var today = new Date();

     var urlParams = new URLSearchParams(window.location.search);
     var switcher = urlParams.get('scheduler');

     $.each(pool, function(idx, elem){

          var release_date = $(elem).data("release-date");

          if (release_date) {

                    cutoff_date = new Date(release_date);

                    if (cutoff_date > today) {

                         if (switcher != "off") {
                              $(elem).remove();
                         }
                    }

          }


     })

}


function renderEvents(){


     $(".menu-handler").bind("click", function(e){
          $(this).toggleClass("clicked");
          $(".mobile-menu").find(".sr-only").toggleClass("hide");
     })

     $(".menuitem-language a").bind("click", switchLang);

}

function renderPageLayout() {

     $("title").html(settings.site_name);

}

function routePage(cbfunc) {

     if ($("html").attr("lang") !== currLangID) {


          var curr_filename = location.href.split("/").slice(-1)[0].split(".");
          var dest_filename = curr_filename[0].split("_")[0] || "index";
          var url;

          switch (currLangID) {
               case "en":
                    url = dest_filename+".html";
                    break;
               default:

                    url = dest_filename+"_"+currLangID+".html";
          }

          location.href= url;
     } else {
          cbfunc();
     }
}

function renderMenus(){

     // render menu
     var menuobj = settings.site_nav;

     var header_menu = $("header .header-menu .nav-menu");
     var mobile_menu = $("header .mobile-menu .nav-menu");
     var footer_menu = $("footer .footer-menu");

     var header_menuitems =$("<ul>");
     var footer_menuitems =$("<ul>");

     var menulabelID = langIDMaper[currLangID];


     var suffix_file = currLangID == "en"?"": "_"+currLangID;


     $.each(menuobj, function(idx, elem){

          if (elem.location.indexOf("header") >= 0) {
               $("<li><a href='"+elem.url+suffix_file+".html'>"+elem.title[menulabelID]+"</a></li>").appendTo(header_menuitems);
          }

          if (elem.location.indexOf("footer") >= 0) {
               $("<li><a href='"+elem.url+suffix_file+".html'>"+elem.title[menulabelID]+"</a></li>").appendTo(footer_menuitems);
          }
     })

     // language button
     var htmlstr = "";
     var lang_array = { // langID : label
          "en" : "EN",
          "zh" : "繁",
          "zh-Hans" : "简"
     };

     $.each(lang_array, function(idx, lbl){
          if (idx !== currLangID){
               htmlstr += "<a data-langid='" + idx +"' href='#'>"+lbl+"</a>";
          }
     });
     $("<li class='menuitem-language'>"+htmlstr+"</li>").appendTo(header_menuitems);

     $(header_menuitems).clone().appendTo(mobile_menu);
     header_menuitems.appendTo(header_menu);
     footer_menuitems.appendTo(footer_menu);


     // disclaimer
     $("footer .footer-disclaimer").html(settings.footer.disclaimer);



     // console.log(settings);


}

function switchLang(e){

     var given_langID = $(this).data("langid") || "en";

     window.localStorage.setItem('langID', given_langID);
     currLangID = given_langID;

     routePage();

}
